/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-18 09:47:04
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-12 15:11:14
 */
export const columns = [
    {
      title: '经销商名称',
      dataIndex: 'name',
      key: 'name',
      align:'center',
      ellipsis:true,
      width: 200,
      scopedSlots: { customRender: 'name'}
    },
    {
      title: '经销商编码',
      dataIndex: 'dealerCode',
      key: 'dealerCode',
      align:'center',
      ellipsis:true,
      width: 150
    },
    {
      title: '大区/事务所',
      dataIndex: 'area',
      key: 'area',
      align:'center',
      scopedSlots: { customRender: 'area'},
      width: 200,
    },
    {
      title: '大区/事务所(共享)',
      dataIndex: 'shareFirmList',
      key: 'shareFirmList',
      scopedSlots: { customRender: 'shareFirmList'},
      width:200,
      align:'center',
    },
    {
      title: '订单&报备',
      dataIndex: 'dealerClassify',
      key: 'dealerClassify',
      scopedSlots: { customRender: 'dealerClassify'},
      width:100,
      align:'center',
    },
    {
      title: '经销商类型',
      dataIndex: 'dealerType',
      key: 'dealerType',
      width:150,
      align:'center',
      ellipsis:true,
      scopedSlots: { customRender: 'dealerType'}
    },
    {
        title: '审核状态',
        dataIndex: 'verifyStatus',
        key: 'verifyStatus',
        width:150,
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'verifyStatus'}
    },
    // {
    //   title: '合作状态',
    //   dataIndex: 'cooperateStatus',
    //   key: 'cooperateStatus',
    //   width:150,
    //   align:'center',
    //   ellipsis:true,
    //   scopedSlots: { customRender: 'cooperateStatus'}
    // },
    // {
    //   title: '联系人',
    //   dataIndex: 'contacts',
    //   key: 'contacts',
    //   align:'center',
    //   ellipsis:true,
    //   width: 200,
    // },
    // {
    //   title: '联系电话',
    //   dataIndex: 'phone',
    //   key: 'phone',
    //   width:150,
    //   align:'center',
    //   ellipsis:true
    // },
    {
      title: '账号',
      dataIndex: 'account',
      key: 'account',
      width:200,
      align:'center',
      ellipsis:true
    },
    // {
    //   title: '员工数量（已有/可建）',
    //   dataIndex: 'numberOfEmployees',
    //   key: 'numberOfEmployees',
    //   width:200,
    //   align:'center',
    //   scopedSlots: { customRender: 'numberOfEmployees'}
    // },
    // {
    //     title: '操作',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width:150,
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'action'}
    // }
]
